<script>
import Nav from "@/components/Nav/index.vue";
import { nextTick } from "vue";
import axios from "axios";
export default {
    name: "Binding",
    data() {
        return {
            nowStep: "personalInfo",
            inputData: {
                gender: "",
                otp: "",
            },
            minutes: 3,
            seconds: 0,
            timerArr: [],
            isLock: false,
            isWrong: false,
            isBind: false,
        };
    },
    components: {
        Nav,
    },
    computed: {
        userNbInfo() {
            return this.$store.state.userNbInfo;
        },
        userLineInfo() {
            return this.$store.state.userLineInfo;
        },
        hostUrl() {
            return this.$store.state.hostUrl;
        },
    },
    watch: {
        userNbInfo: function () {
            let vm = this;
            if (vm.userNbInfo.data.user.isBind) {
                vm.$router.push("/");
            }
        },
        nowStep: function (newV) {
            const _this = this;
            _this.isBind = false;
            _this.isWrong = false;
            _this.isLock = false;
            if (newV === "messageOTP") {
                _this.$nextTick(() => {
                    window.scrollTo(0, 0);
                });
            }
        },
    },
    mounted() {
        nextTick(() => {});
    },
    methods: {
        checkOTP(e) {
            // console.log("checkOTP", e.target.value);
            const input = e.target;
            const value = input.value;
            const validNum = /^\d{1}$/;

            if (value.length === Number(input.getAttribute("maxLength"))) {
                if (!validNum.test(value)) {
                    input.value = "";
                } else {
                    const nextInput =
                        input.parentElement.nextElementSibling?.firstElementChild;
                    if (nextInput) {
                        nextInput.focus();
                    }
                }
            }
        },
        checkInput() {
            const _this = this;
            console.log("checkInput");

            if (_this.nowStep === "personalInfo") {
                if (!_this.inputData.isRead) {
                    alert("請確認是否已詳閱並同意相關使用須知");
                    return;
                }

                const validMobile = /^09\d{8}$/;
                if (!validMobile.test(_this.inputData.phone)) {
                    alert("請確認行動電話格式");
                    return;
                }

                _this.getOTP();
                return;
            }

            if (_this.nowStep === "messageOTP") {
                _this.inputData.otp = "";
                for (let i = 0; i <= 5; i++) {
                    _this.inputData.otp += _this.inputData[`otp${i}`] || "";
                }
                console.log(_this.inputData.otp);

                if (_this.inputData.otp.length !== 5) {
                    _this.isWrong = true;
                    return;
                }

                _this.verifyOTP();
                return;
            }

            if (_this.nowStep === "signUp") {
                if (!_this.inputData.name || _this.inputData.name === "") {
                    alert("請輸入姓名");
                    return;
                }

                if (_this.inputData.gender === "") {
                    alert("請選擇性別");
                    return;
                }

                if (!_this.inputData.birthday || _this.inputData.birthday === "") {
                    alert("請選擇生日");
                    return;
                }

                _this.inputData.dateReplace = _this.inputData.birthday.replace(/-/g, "/");
                _this.sendSignUp();
            }
        },
        startCountdown() {
            const _this = this;
            const duration = 3 * 60;
            let timer = duration;
            const interval = setInterval(function () {
                _this.minutes = Math.floor(timer / 60);
                _this.seconds = timer % 60;
                if (--timer < 0) {
                    clearInterval(interval);
                    _this.isWrong = true;
                }
            }, 1000);
            _this.timerArr.push(interval);
        },
        getOTP() {
            console.log("getOTP");
            const _this = this;

            _this.isLock = true;
            _this.isWrong = false;
            _this.timerArr.forEach(timer => clearInterval(timer));

            for (let i = 1; i <= 5; i++) {
                _this.inputData[`otp${i}`] = "";
            }
            _this.inputData.otp = "";
            _this.startCountdown();

            const data = {
                phone: _this.inputData.phone,
            };

            axios
                .post(`${_this.hostUrl}api/binding/otp/send`, data)
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        _this.isLock = false;
                        nextTick(() => {
                            _this.nowStep = "messageOTP";
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.error === "40001") {
                            _this.isBind = true;
                        }
                    } else {
                        alert("伺服器忙碌中，請稍後再試");
                    }
                    console.log(error);
                });
        },
        verifyOTP() {
            console.log("verifyOTP");
            const _this = this;

            _this.isLock = true;
            _this.isWrong = false;

            const data = {
                phone: _this.inputData.phone,
                otp: _this.inputData.otp,
            };

            axios
                .post(`${_this.hostUrl}api/binding/otp/verify`, data)
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        if (response.data.member !== null) {
                            const { birthday, gender, name } = response.data.member;
                            _this.inputData.gender = gender;
                            _this.inputData.name = name;
                            _this.inputData.birthday = birthday.replace(/\//g, "-");
                        }

                        nextTick(() => {
                            _this.nowStep = "signUp";
                            _this.isBind = false;
                            _this.isWrong = false;
                        });
                    }
                })
                .catch(error => {
                    _this.isLock = false;
                    if (error.response.status === 400) {
                        if (error.response.data.error === "40002") {
                            _this.isWrong = true;
                        }
                    } else {
                        alert("伺服器忙碌中，請稍後再試");
                    }
                    console.log(error);
                });
        },
        sendSignUp() {
            console.log("sendSignUp");
            const _this = this;
            _this.$store.commit("loadingToggle", true);
            _this.isLock = true;

            const data = {
                lineUid: _this.userLineInfo.userId,
                phone: _this.inputData.phone,
                name: _this.inputData.name,
                birthday: _this.inputData.birthday.replace(/-/g, "/"),
                gender: _this.inputData.gender,
            };

            axios
                .post(`${_this.hostUrl}api/binding/register`, data)
                .then(response => {
                    console.log(response);

                    if (response.status === 200) {
                        nextTick(() => {
                            _this.$store.dispatch("userLogin");
                        });
                    }
                })
                .catch(error => {
                    _this.isLock = false;
                    if (error.response.status === 400) {
                        if (error.response.data.error === "40002") {
                            _this.isWrong = true;
                        }
                    } else {
                        alert("伺服器忙碌中，請稍後再試");
                    }
                    console.log(error);
                });
        },
    },
};
</script>

<template src="./template.pug" lang="pug"></template>

<style src="./style.sass" lang="sass" scoped></style>
